<template>
  <div>
    <div class="d-flex flex-row-reverse">
      <button
        v-if="$route.meta.idTab == 1 && moduleId == 17"
        type="button"
        class="btn btn-success ml-2 mb-2"
        @click="openModal(1, newForm)"
      >
        <b>+ CREATE</b>
      </button>
    </div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refVacationsTable'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refVacationsTable"
          small
          no-provider-filtering
          :items="search"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(employee)="data">
            <span>
              <feather-icon
                icon="UserIcon"
                size="20"
              />
              {{ data.item.employee }}
            </span>
          </template>

          <template #cell(createdby)="data">
            <span>
              <feather-icon
                icon="UserIcon"
                size="20"
              />
              {{ data.item.createdby }}
            </span>
            <br>
            <span>
              <feather-icon
                icon="CalendarIcon"
                size="20"
              />
              {{ data.item.created_at | myGlobalDay }}
            </span>
          </template>

          <template #cell(updatedby)="data">
            <div v-if="data.item.updatedby">
              <span>
                <feather-icon
                  icon="UserIcon"
                  size="20"
                />
                {{ data.item.updatedby }}
              </span>
              <br>
              <span>
                <feather-icon
                  icon="CalendarIcon"
                  size="20"
                />
                {{ data.item.updated_at | myGlobalDay }}
              </span>
            </div>
            <span v-else> - </span>
          </template>

          <template #cell(start)="data">
            <b-button variant="outline-primary">
              {{ data.item.start | myGlobal }}
            </b-button>
          </template>

          <template #cell(end)="data">
            <b-button variant="outline-success">
              {{ data.item.end | myGlobal }}
            </b-button>
          </template>

          <template #cell(hours)="data">
            <span>
              <feather-icon
                icon="WatchIcon"
                size="20"
              />{{
                data.item.justification_hours | totalVacationHours
              }}
              hrs
            </span>
          </template>

          <template #cell(actions)="data">
            <feather-icon
              v-if="isPastDate(data.item.end) && idTab === 1 && moduleId == 17"
              class="text-warning mr-2 cursor-pointer"
              icon="EditIcon"
              size="20"
              @click="openModal(2, data.item)"
            />

            <feather-icon
              v-if="isPastDate(data.item.end) && idTab === 1 && moduleId == 17"
              class="text-danger cursor-pointer"
              icon="Trash2Icon"
              size="20"
              @click="deleteVacations(data.item)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-add-vacation
      v-if="modal"
      :operation="operation"
      :vacation="vacationSelected"
      :usersarray="usersArray"
      @hidden="modal = false"
      @closeModal="modal = false"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import Fields from '@/views/assistance/views/dashboard/components/vacations/views/data/fields.vacations.data';
import filtersData from '@/views/assistance/views/dashboard/components/vacations/views/data/filters.vacations.data';
import Service from '@/views/assistance/views/dashboard/components/vacations/services/vacations.service';
import ModalAddVacation from '@/views/assistance/views/dashboard/components/vacations/views/components/ModalAddVacations.vue';
import moment from 'moment';

export default {
  components: {
    ModalAddVacation,
  },
  filters: {
    totalVacationHours(value) {
      let justificationHours = [];
      justificationHours = JSON.parse(value);
      let total = 0;
      if (justificationHours.length !== 0) {
        for (let index = 0; index < justificationHours.length; index += 1) {
          total += justificationHours[index].hours;
        }
      }
      return total;
    },
  },
  data() {
    return {
      idTab: this.$route.meta.idTab,
      newForm: {
        employee_id: null,
        start: null,
        end: null,
        observations: '',
      },
      operation: 1,
      modal: false,
      vacationSelected: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'name',
      sortDesc: false,
      fields: Fields,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Employee name...',
        model: '',
      },
      filter: filtersData,
      transactionId: null,
      usersArray: [],
    };
  },

  computed: {
    visibleFields() {
      return this.fields;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.listAllUsers();
    this.optionsYears();
  },

  methods: {
    optionsYears() {
      this.filter[4].options = [{ value: null, label: 'All' }];
      for (let index = 2023; index <= moment().year(); index += 1) {
        this.filter[4].options.push({ value: index, label: index });
      }
    },
    isPastDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();

      if (date > now) {
        return true;
      }
      return false;
    },

    async deleteVacations(obj) {
      const resultState = await this.showConfirmSwal();

      if (resultState.isConfirmed) {
        const resul = await Service.deleteVacations(obj);
        if (resul.status === 200) {
          this.showSuccessSwal();
          this.refreshTable();
        }
      }
    },

    refreshTable() {
      this.$refs.refVacationsTable.refresh();
    },

    openModal(value, vacation) {
      this.operation = value;
      this.vacationSelected = vacation;
      this.modal = true;
    },

    async search(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          employee_id: this.filter[0].model,
          from: this.filter[1].model,
          to: this.filter[2].model,
          month: this.filter[3].model,
          year: this.filter[4].model,
          id_tab: this.$route.meta.idTab,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
        };

        const res = await Service.getVacations(obj);
        if (res.status === 200) {
          this.clients = res.data.data;
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;

          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async listAllUsers() {
      try {
        const res = await Service.listAllUsers();
        if (res.status === 200) {
          this.filter[0].options = res.data;
          this.usersArray = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.tdClass {
  font-size: 14px;
  padding: 7px 10px !important;
}
</style>
